<template>
  <div>
    <v-dialog v-model="terms" width="70%">
      <v-card>
        <v-card-title class="title">Terms and conditions</v-card-title>
        <v-card-text v-for="n in 5" :key="n">{{ content }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="purple" @click="terms = false">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" absolute top right color="success">
      <span>Registration successful!</span>
      <v-icon dark>mdi-checkbox-marked-circle</v-icon>
    </v-snackbar>

    <AppBar />
    <v-content>
      <v-container fluid>
        <v-flex>
          <v-alert v-if="error" type="error">{{ error }}</v-alert>
          <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="submit">
            <v-text-field v-model="name" :counter="10" label="Name" prepend-icon="mdi-account"></v-text-field>

            <v-text-field v-model="email" :rules="emailRules" label="E-mail" prepend-icon="mdi-email" required></v-text-field>

            <v-text-field v-model="password" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :rules="passwordRules" :type="show1 ? 'text' : 'password'" name="input-10-1" label="Password" hint="At least 8 characters" prepend-icon="mdi-lock" counter @click:append="show1 = !show1"></v-text-field>

            <v-checkbox v-model="checkbox" :rules="[v => !!v || 'You must agree to continue!']" color="green">
              <template v-slot:label>
                <div @click.stop>
                  Do you accept the
                  <a href="javascript:;" @click.stop="terms = true">terms and conditions?</a>
                </div>
              </template>
            </v-checkbox>

            <v-btn :disabled="!valid" color="success" class="mr-4" type="submit">Validate</v-btn>

            <v-btn color="error" class="mr-4" @click="reset">Clear</v-btn>
          </v-form>
        </v-flex>
      </v-container>
    </v-content>

    <div class="text-center">
      <v-dialog v-model="dialog" persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            Please stand by
            <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import AppBar from "@/components/AppBar.vue";

const sleep = milliseconds => {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
};

export default {
  data: () => ({
    valid: false,
    dialog: false,
    name: "",
    nameRules: [v => !!v || "Name is required", v => (v && v.length <= 10) || "Name must be less than 10 characters"],
    email: "",
    emailRules: [v => !!v || "E-mail is required", v => /.+@.+\..+/.test(v) || "E-mail must be valid"],
    show1: false,
    password: "",
    passwordRules: [v => !!v || "Password is required"],

    checkbox: false,

    error: null,

    content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi. Nulla quis sem at nibh elementum imperdiet. Duis sagittis ipsum. Praesent mauris. Fusce nec tellus sed augue semper porta. Mauris massa. Vestibulum lacinia arcu eget nulla. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur sodales ligula in libero. Sed dignissim lacinia nunc.`,
    snackbar: false,
    terms: false
  }),

  components: {
    AppBar
  },

  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.dialog = true;

        sleep(2000).then(() => {
          this.dialog = false;
          this.snackbar = true;
        });
      }
    },
    reset() {
      this.$refs.form.reset();
      this.error = null;
    }
  }
};
</script>
